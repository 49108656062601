import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import ContactInfo from '../components/modules/ContactInfo';
import Seo from "../components/modules/Seo";
import config from "../../data/SiteConfig";


const Contact = (props) => {
  return (
    <Layout>
      <div className="contact">
        <Helmet title={config.siteTitle} />
        <ContactInfo />
      </div>
    </Layout>
  );
}
export default Contact;
