import React from 'react';
import { useWindowDimensions } from '../context/WindowDimensionsProvider';
import { useTrail, animated as a } from "react-spring";

let items = [
    {
      name: 'Contact',
    },
    {
      name: 'stein.olsen@uib.no'
    },
    {
      name: 'phone +47 977 66 573'
    },
    {
      name: 'Gerhard Grans Vei 45'
    },
    {
      name: '5094 Bergen'
    },
    {
      name: 'Norway'
    }
]
const config = { mass: 5, tension: 2000, friction: 300 };

const ContactInfo = () => {
  const { windowHeight, footerHeight } = useWindowDimensions();
  const trail = useTrail(items.length, {
    config,
    opacity: 0,
    from: { opacity: 0, x: -20 },
    to: { opacity: 1, x: 0 }
  });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  return(
     <div className="flex center column" style={{minHeight: windowHeight}}>
       {trail.map(({ x, height, ...rest }, index) => (
          <a.div
              style={{
                ...rest,
                transform: x.to(x => `translate3d(0,${x}px,0)`)
              }}
              key={index}
              className="trails-text"
              >
                <h3>{items[index].name}</h3>
          </a.div>
        ))}
  </div>
)
}

export default ContactInfo;
